var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"relationship-item"},[(_vm.relationship)?_c('div',[(
                _vm.finishedLoading &&
                _vm.instanceOf !== undefined &&
                _vm.instanceOf.primaryID
            )?_c('span',[_c('span',{attrs:{"id":("" + _vm.global_randomID)}},[_c('span',{staticClass:"relationship-name",on:{"click":_vm.onClickInstanceOf}},[_vm._v(" "+_vm._s(_vm.instanceOf.primaryLabel)+" ")]),_c('span',{staticClass:"short-id",on:{"click":_vm.onClickInstanceOf}},[_vm._v(" "+_vm._s(_vm.shortID)+" ")])]),(_vm.instanceOf.primaryID)?_c('TextActions',{attrs:{"slot":"actions","copyTargetName":'short-form ID',"copyTargetEl":function () { return _vm.shortID; },"copyFullText":_vm.shortID,"searchTargetName":'class',"searchTargetEl":function () { return ("" + (_vm.instanceOf && _vm.instanceOf.primaryID)); }},slot:"actions"}):_vm._e(),(
                    _vm.instanceOf &&
                    _vm.instanceOf.primaryLabel &&
                    _vm.instanceOf.textualDefinitions
                )?_c('PropertyValuePopup',{attrs:{"primaryID":_vm.instanceOf.primaryID,"primaryLabel":_vm.instanceOf.primaryLabel,"description":_vm.instanceOf.textualDefinitions[0],"target":("" + _vm.global_randomID)}}):_vm._e()],1):_c('div',{staticClass:"relationship-loading"},[_vm._v(" Loading "+_vm._s(_vm.relationshipIsInstance ? 'Instance' : 'Class')+" Data... ")])]):_vm._e(),(_vm.instance)?_c('div',[_c('span',{attrs:{"id":("" + _vm.global_randomID)}},[_c('span',{staticClass:"relationship-name",attrs:{"id":"relationship-name"},on:{"click":_vm.onClickRelatedInstance}},[_vm._v(" "+_vm._s(_vm.instance.primaryLabel)+" ")]),_c('span',{staticClass:"short-id",on:{"click":_vm.onClickRelatedInstance}},[_vm._v(" "+_vm._s(_vm.instance.shortFormIDs[0])+" ")]),(
                    _vm.instance.primaryID &&
                    _vm.instance.shortFormIDs &&
                    _vm.instance.shortFormIDs[0].length
                )?_c('TextActions',{attrs:{"slot":"actions","copyTargetName":'short-form ID',"copyTargetEl":function () { return ("" + (_vm.instance &&
                            _vm.instance.shortFormIDs &&
                            _vm.instance.shortFormIDs[0])); },"copyFullText":("" + (_vm.instance &&
                    _vm.instance.shortFormIDs &&
                    _vm.instance.shortFormIDs[0])),"searchTargetName":'instance',"searchTargetEl":function () { return ("" + (_vm.instance && _vm.instance.primaryID)); }},slot:"actions"}):_vm._e(),(
                    _vm.instance &&
                    _vm.instance.primaryID &&
                    _vm.instance.primaryLabel &&
                    _vm.instance.textualDefinitions
                )?_c('PropertyValuePopup',{attrs:{"primaryID":_vm.instance.primaryID,"primaryLabel":_vm.instance.primaryLabel,"description":_vm.instance.textualDefinitions[0],"target":("" + _vm.global_randomID)}}):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }