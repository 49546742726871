import { render, staticRenderFns } from "./ PropertySearch.vue?vue&type=template&id=813f14a6&scoped=true&"
import script from "./ PropertySearch.vue?vue&type=script&lang=ts&"
export * from "./ PropertySearch.vue?vue&type=script&lang=ts&"
import style0 from "./ PropertySearch.vue?vue&type=style&index=0&id=813f14a6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "813f14a6",
  null
  
)

export default component.exports