import { Ref, ref } from '@vue/composition-api';
import { components } from '@/ts/ApiSpecs';
import { getInstanceByPrimaryId, getOntologyInstancesPage } from '@/api-v2';
import { useOntology } from '@/compositions';

const instances: Ref<components['schemas']['OntologyInstanceSummaryVM'][]> =
    ref([]);
const selectedInstance: Ref<
    components['schemas']['OntologyInstanceSummaryVM'] | undefined
> = ref();
const totalInstanceCount = ref(0);

export const useInstanceView = () => {
    const ontology = useOntology().getOntologyId.value;

    const setSelectedInstance = (
        instance: components['schemas']['OntologyInstanceSummaryVM']
    ) => {
        selectedInstance.value = { ...instance };
    };

    const getSelectedInstance = () => {
        return selectedInstance.value;
    };

    const setInstances = (
        instance: components['schemas']['OntologyInstanceSummaryVM'][]
    ) => {
        instances.value = [...instance];
    };

    const getInstances = () => {
        return instances.value;
    };

    const setTotalInstanceCount = (num: number) => {
        totalInstanceCount.value = num;
    };

    const getTotalInstanceCount = () => {
        return totalInstanceCount.value;
    };

    const loadInitialInstances = async () => {
        const data = await getOntologyInstancesPage(
            { ontologyId: ontology },
            { from: 0, size: 20 }
        );
        if (!data.elements) return;
        setInstances(data.elements);
        setTotalInstanceCount(data.total || 0);
    };

    const loadInstance = async (instanceID: string) => {
        if (
            instances.value.filter(
                (instance) => instance.primaryID === instanceID
            ).length
        )
            return;
        const fetchInstance = await getInstanceByPrimaryId(
            { ontologyId: ontology },
            { primaryId: instanceID }
        );
        setInstances([fetchInstance, ...getInstances()]);
        setSelectedInstance(fetchInstance);
    };

    return {
        setInstances,
        getInstances,
        selectedInstance,
        setSelectedInstance,
        getSelectedInstance,
        setTotalInstanceCount,
        getTotalInstanceCount,
        loadInitialInstances,
        loadInstance,
    };
};
