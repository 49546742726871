import { render, staticRenderFns } from "./InstanceList.vue?vue&type=template&id=975beac2&scoped=true&"
import script from "./InstanceList.vue?vue&type=script&lang=ts&"
export * from "./InstanceList.vue?vue&type=script&lang=ts&"
import style0 from "./InstanceList.vue?vue&type=style&index=0&id=975beac2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "975beac2",
  null
  
)

export default component.exports