var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('class-typeahead',{ref:"typeahead",attrs:{"placeholder":("Instance within " + _vm.shortDisplayName),"approximate-title":("NO INSTANCE FOUND WITHIN " + _vm.shortDisplayName + ". Did you mean..."),"matched-title":'INSTANCE WITHIN ' + _vm.shortDisplayName,"disabled":_vm.isReadonly,"isAutofocus":false,"suggestion-max-matches":_vm.suggestionMax,"fetchSuggestions":_vm.fetchTypeaheadList,"shouldSearchClass":true,"force-instance":true},scopedSlots:_vm._u([{key:"inset",fn:function(typeahead){return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(
                !_vm.isReadonly &&
                typeahead.query &&
                typeahead.query === _vm.currInstanceId
            ),expression:"\n                !isReadonly &&\n                typeahead.query &&\n                typeahead.query === currInstanceId\n            "}],staticClass:"reset-btn inset-btn",attrs:{"variant":"link"},on:{"click":function($event){return _vm.$emit('breadcrumb:reload')}}},[_c('font-awesome-icon',{attrs:{"icon":"undo-alt","size":"sm"}})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(
                !_vm.isReadonly &&
                typeahead.query &&
                typeahead.query !== _vm.currInstanceId
            ),expression:"\n                !isReadonly &&\n                typeahead.query &&\n                typeahead.query !== currInstanceId\n            "}],staticClass:"inset-btn",attrs:{"variant":"link"},on:{"click":function () {
                    if (!_vm.$store.getters.isObsolete) {
                        _vm.currInstanceId = typeahead.query;
                        _vm.$refs.typeahead.onAction(typeahead.query);
                    }
                }}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-right","size":"sm"}})],1)]}}])},[_c('template',{slot:"view-all"},[_c('b-button',{staticClass:"view-all-btn rounded-0 border-0",attrs:{"variant":"outline-primary"}},[_c('small',[_vm._v("View all results in "+_vm._s(_vm.shortDisplayName))])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }